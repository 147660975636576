import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Header from './components/Header'
import Login from './components/Login'
import RequestForm from './components/RequestForm'
import Success from './components/Success'
import Fail from './components/Fail'
import NotAuthorized from './components/NotAuthorized'
import ProtectedRoute from './components/ProtectedRoute'
import { useData } from './components/DataContext'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from './theme'

function App () {
  const { data } = useData()
  return (
    <>
      <ThemeProvider theme={theme}>
        <Header />
        <Router>
          <Switch>
            <Route exact path='/'>
              <Login />
            </Route>
            <ProtectedRoute path='/request-form' isAuth={data.isAuth} component={RequestForm} />
            <ProtectedRoute path='/request-form-success-message' isAuth={data.isAuth} component={Success} />
            <ProtectedRoute path='/request-form-fail-message' isAuth={data.isAuth} component={Fail} />
            <ProtectedRoute path='/not-authorized' isAuth={data.isAuth} component={NotAuthorized} />
          </Switch>
        </Router>
      </ThemeProvider>
    </>
  )
}

export default App
