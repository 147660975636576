import * as yup from 'yup'

const schema = yup.object().shape({
  Barcode: yup
    .string()
    .required('Please enter a valid Library card number. The field cannot be left blank.')
    .length(14, 'Card number must be contain fourteen digits')
    .matches(/^[0-9]*$/, 'Card number must be numeric'),
  Password: yup
    .string()
    .required('Please enter a valid password. The field cannot be left blank.')
})

export default schema
