import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import Typography from '@material-ui/core/Typography'
import CPLButton from './CPLButton'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import BookIcon from '@material-ui/icons/Book'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import MainContainer from './MainContainer'
import Form from './Form'
import TxtInput from './Input'
import initState from '../initState'
import { useData } from './DataContext'
import FormSkeleton from './FormSkeleton'
import LimitReached from './LimitReached'
import makeRecommendation from '../api/makeRecommendation'
import requestFormschema from '../requestFormschema'
import { requestFormInitValues } from '../requestFormInitValues'
import getRecomendationCount from '../api/getRecomendationCount'

const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  hidden: {
    display: 'none'
  }
}))

const { REACT_APP_LOGICAPP_URL } = process.env
const RequestForm = () => {
  const classes = useStyles()
  const { setValues, data } = useData()
  const { EmailAddress, Barcode, RecommendationCount, isLoading, Session, showSpinner } = data
  const history = useHistory()
  const methods = useForm({
    mode: 'all',
    shouldUnregister: true,
    resolver: yupResolver(requestFormschema),
    defaultValues: requestFormInitValues
  })

  const {
    handleSubmit: handleSubmitRf,
    control: controlRf,
    formState: { errors: errorsRf }
  } = methods

  const onSubmit = async (data) => {
    setValues({ showSpinner: true })
    const fetchData = await makeRecommendation(data, REACT_APP_LOGICAPP_URL)
    if (fetchData.status === 200) {
      history.push('/request-form-success-message')
      setValues({ isLoading: true })
    }
    // To do: handle other responses
  }
  const logout = () => {
    setValues(initState)
    history.push('/')
  }

  useEffect(() => {
    const fetchData = async (email, cn) => {
      const getData = await getRecomendationCount(email, cn)
      const resBody = await getData.text()
      setValues(JSON.parse(resBody))
      setValues({ isLoading: false })
    }
    fetchData(EmailAddress, Barcode)// eslint-disable-next-line
  }, [])

  if (isLoading) {
    return <FormSkeleton />
  }
  if (RecommendationCount < 5) {
    return (
      <MainContainer>
        <Box mb={2}>
          <Chip
            icon={<BookIcon fontSize='small' />}
            label={`Monthly suggestions ${RecommendationCount}/5`}
            style={{ width: '200px' }}
          />
        </Box>

        <Typography variant='body1'>You can fill out material request form here</Typography>
        <FormProvider {...methods} autoComplete='off'>
          <Form onSubmit={handleSubmitRf(onSubmit)}>
            <TxtInput
              name='Session'
              label='Session'
              required
              defaultValue={Session}
              disabled
              className={classes.hidden}
            />
            <TxtInput
              name='Title'
              label='Title'
              required
              inputProps={{ 'aria-label': 'Title' }}
              error={!!errorsRf.Title}
              helperText={errorsRf?.Title?.message}
            />
            <TxtInput
              name='Author'
              label='Author'
              required
              inputProps={{ 'aria-label': 'Author' }}
              error={!!errorsRf.Author}
              helperText={errorsRf?.Author?.message}
            />
            <FormControl
              fullWidth
              required
              variant='outlined'
              margin='normal'
              error={!!errorsRf.Media}
            >
              <InputLabel>Format:</InputLabel>
              <Controller
                defaultValue=''
                control={controlRf}
                name='Media'
                render={({ field }) => (
                  <Select
                    {...field}
                    label='Format:'
                  >
                    <MenuItem value='Book - Fiction'>Book - Fiction</MenuItem>
                    <MenuItem value='Book - Non-Fiction'>Book - Non-Fiction</MenuItem>
                    <MenuItem value='eBook'>eBook</MenuItem>
                    <MenuItem value='eAudiobook'>eAudiobook</MenuItem>
                    <MenuItem value='Graphic Novel'>Graphic Novel</MenuItem>
                  </Select>
                )}
              />
              {errorsRf.Media ? <FormHelperText>{errorsRf.Media.message}</FormHelperText> : null}
            </FormControl>
            <TxtInput
              name='ISBN'
              label='ISBN'
              inputProps={{ 'aria-label': 'ISBN' }}
              error={!!errorsRf.ISBN}
              helperText={errorsRf?.ISBN?.message}
            />
            <FormControl
              fullWidth
              required
              variant='outlined'
              margin='normal'
              error={!!errorsRf.Audience}
            >
              <InputLabel>Audience:</InputLabel>
              <Controller
                defaultValue=''
                control={controlRf}
                name='Audience'
                render={({ field }) => (
                  <Select
                    {...field}
                    label='Audience:'
                  >
                    <MenuItem value='Adult'>Adults</MenuItem>
                    <MenuItem value='Kids'>Kids</MenuItem>
                    <MenuItem value='Teens'>Teens</MenuItem>
                  </Select>
                )}
              />
              {errorsRf.Audience ? <FormHelperText>{errorsRf.Audience.message}</FormHelperText> : null}
            </FormControl>
            <TxtInput
              multiline
              rows='6'
              name='Notes'
              label='Notes'
              inputProps={{ 'aria-label': 'Notes' }}
              error={!!errorsRf.Notes}
              helperText={errorsRf?.Notes?.message}
            />
            <Box mt={2}>
              <CPLButton
                type='submit'
                color='secondary'
                size='large'
                variant='contained'
                disabled={showSpinner}
                disableElevation
              >
                Submit
                {showSpinner && (<CircularProgress size={24} className={classes.buttonProgress} />)}
              </CPLButton>
              <Box ml={2} component='span'>
                <CPLButton onClick={() => logout()}>Cancel</CPLButton>
              </Box>
            </Box>
          </Form>
        </FormProvider>
      </MainContainer>
    )
  } else {
    return <LimitReached />
  }
}

export default RequestForm
