import React from 'react'
import { useHistory } from 'react-router'
import { Button, Link  } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { makeStyles } from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Form from './Form'
import MainContainer from './MainContainer'
import { useData } from './DataContext'
import BarcodeInput from './BarcodeInput'
import schema from '../schema'
import getAuth from '../api/getAuth'

const useStyles = makeStyles(theme => ({
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}))

const Login = () => {
  const classes = useStyles()
  const { setValues, data } = useData()
  const history = useHistory()

  const methods = useForm({
    mode: 'all',
    shouldUnregister: true,
    resolver: yupResolver(schema)
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError
  } = methods

  const dispalyAuthError = (resBody) => {
    const msg = JSON.parse(resBody).error
    setError('CardNumber/PinNumber', { type: 'ServerSide', error: msg })
  }

  const onSubmit = async (data) => {
    setValues({ showSpinner: true })
    const isAuth = await getAuth(data.Barcode, data.Password)
    const resBody = await isAuth.text()
    if (isAuth.status === 200) {
      setValues({ isAuth: true, isLoading: true })
      setValues(JSON.parse(resBody))
      history.push('/request-form')
      setValues({ showSpinner: false })
    }

    if (isAuth.status === 400) {
      setValues({ showSpinner: false })
      dispalyAuthError(resBody)
    }

    if (isAuth.status === 403) {
      setValues({ isAuth: true, isLoading: true })
      history.push('/not-authorized')
      setValues({ showSpinner: false })
    }
    // else technical issue
  }

  const handleClickShowPassword = () => {
    setValues({ showPassword: !data.showPassword })
  }
  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleChange = (i) => {
    setValues({ Barcode: i })
  }
  return (
    <MainContainer>
      <Typography variant='h4' component='h2' gutterBottom>Login</Typography>

      <Alert severity="info">
      <AlertTitle>Is there a title you’d like us to purchase?</AlertTitle>
        <Typography variant='body2' gutterBottom>
          We want to make sure the books you're interested in are available on Library shelves. If you don't see an item you're looking for, let us know.
        </Typography>
        <Typography variant='body2' gutterBottom>
          The Library attempts to purchase in consideration of popularity, currency, budget, and availability.   
        </Typography>
        <Typography variant='body2' gutterBottom>
          The Library is focusing on book, e-book and e-audio suggestions at this time. 
          There is declining availability to purchase physical copies of audio-visual materials. 
        </Typography>
        <Typography variant='body2' gutterBottom>
          Starting September1, 2023, the option to suggest music CDs and DVDs for purchase will be discontinued.  
        </Typography>
        <Typography variant='body2' gutterBottom>
          The Library will continue to acquire mainstream and popular music CDs and DVDs which are available from library vendors. 
        </Typography>
        <Typography variant='body2' gutterBottom>  
          Library staff can answer questions about this change. Please contact the Library Hotline at <Link href='mailto:libraryhotline@calgarylibrary.ca' onClick={(e)=>e.preventDefault()}>libraryhotline@calgarylibrary.ca</Link>
        </Typography>
      </Alert>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
          <BarcodeInput
            name='Barcode'
            label='Username or Barcode:'
            required
            inputProps={{ 'aria-label': 'Barcode' }}
            error={!!errors.Barcode || !!errors['CardNumber/PinNumber']}
            helperText={errors?.Barcode?.message}
            onChange={e => handleChange(e)}
          />

          <Controller
            defaultValue=''
            name='Password'
            control={control}
            render={({ field }) => (
              <FormControl
                className={classes.margin}
                variant='outlined'
                fullWidth
                required
                error={!!errors.Password || !!errors['CardNumber/PinNumber']}
              >
                <InputLabel htmlFor='outlined-adornment-password'>
                  PIN:
                </InputLabel>
                <OutlinedInput
                  // The dash (--) in the lable prop will not be dipalyed on the UI

                  label='PIN --'
                  {...field}
                  autoComplete='on'
                  type={data.showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {data.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                }
                  labelWidth={85}
                />
                {/* {errors.Password ? <FormHelperText>{errors.Password.error}</FormHelperText> : null} */}
                {errors['CardNumber/PinNumber'] ? <FormHelperText>{errors['CardNumber/PinNumber'].error}</FormHelperText> : null}
              </FormControl>
            )}
          />
          <Button
            fullWidth
            size='large'
            type='submit'
            variant='contained'
            color='primary'
            disabled={data.showSpinner}
            onClick={() => onSubmit}
          >
            log in
            {data.showSpinner && (<CircularProgress size={24} className={classes.buttonProgress} />)}
          </Button>
        </Form>
      </FormProvider>
    </MainContainer>
  )
}

export default Login
