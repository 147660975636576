import React from 'react'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import BottomNavigation from '@material-ui/core/BottomNavigation'

const useStyles = makeStyles((theme) => ({
  alertBar: {
    background: '#d93930',
    boxShadow: 'none'
  },
  alertToolbar: {
    minHeight: 120,
    justifyContent: 'center'
  }
}))

const MainContainer = ({ children, ...props }) => {
  const styles = useStyles()
  return (
    <>
      <AppBar position='static' className={styles.alertBar}/>
      <Container className={styles.root} component='main' maxWidth='sm' {...props}>
        <Box mt={2}>
          {children}
        </Box>
      </Container>
      <BottomNavigation />
    </>
  )
}

export default MainContainer