import React from 'react'
import MainContainer from './MainContainer'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

const LimitReached = () => {
  return (
    <MainContainer>
      <Box
        textAlign='center'
      >
        <Typography gutterBottom variant='h4' component='h1'>
          <span role='img' aria-label='emoji'>🙌</span>
        </Typography>
        <Typography gutterBottom variant='h4' component='h1'>Unfortunately, you have reached the monthly limit.</Typography>
        <Typography gutterBottom variant='h5' component='h2'>Please try again next month.</Typography>
        <Typography gutterBottom variant='body1'>Each patron is allowed to submit a maximum of five requests per month.</Typography>
      </Box>
    </MainContainer>
  )
}

export default LimitReached
