const initState = {
  isSubmittig: null,
  showSpinner: null,
  isLoading: null,
  isAuth: false, 
  showPassword: false,
  Barcode: '',
  EmailAddress: null, 
  FirstName: '', 
  LastName: '', 
  RecommendationCount: null,

  Session: '',
  Message: null,
  Valid: null,
  Code: null,
}

export default initState
