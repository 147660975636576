const getAuth = async (barcode, password) => {
  const myHeaders = new window.Headers()
  myHeaders.append('x-functions-key', '/khaGkhfytp6TjBEWjJLKB3edYRyDzNh4nLtha2cFafMkJNq8yasGw==')
  myHeaders.append('Content-Type', 'application/json')

  const raw = JSON.stringify({
    username: barcode,
    password: password
  })

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  }
  const authUser = await window.fetch('https://prod-00.canadacentral.logic.azure.com:443/workflows/f72c16c888dc40b08952d04d71f19241/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=33o0-shGuCBlr8h9tBtOhKb7Jn3OrklMqaLSFe_5Ljk', requestOptions)
    .catch(error => {
      console.log(error)
    })
  return authUser
}

export default getAuth
