import React from 'react'
import TextField from '@material-ui/core/TextField'
import { Controller, useFormContext } from 'react-hook-form'

const TxtInput = ({ name, defaultValue, ...props }) => {
  const { control, ...rest } = useFormContext()
  return (
    <Controller
      defaultValue={defaultValue || ''}
      name={name}
      control={control}
      render={({ field }) =>
        <TextField
          {...props}
          {...field}
          variant='outlined'
          margin='normal'
          fullWidth
        />}
      {...rest}
    />
  )
}

export default TxtInput
