import React, { createContext, useState, useContext } from 'react'
import initState from '../initState'

const DataConetext = createContext()

export const DataProvider = ({ children }) => {
  const [data, setData] = useState(initState)
  const setValues = (values) => {
    setData(prevData => ({
      ...prevData,
      ...values
    }))
  }
  return <DataConetext.Provider value={{ data, setValues }}>{children}</DataConetext.Provider>
}

export const useData = () => useContext(DataConetext)
