import * as yup from 'yup'

const requestFormschema = yup.object().shape({
  Title: yup
    .string()
    .required('Please enter a Title. The field cannot be left blank.'),
  Author: yup
    .string()
    .required('Please enter an Author name. The field cannot be left blank.'),
  Media: yup
    .string()
    .required('Please select a media. The field cannot be left blank.'),
  Audience: yup
    .string()
    .required('Please select an Audience. The field cannot be left blank.'),
  Notes: yup
    .string()

})

export default requestFormschema
