import React from 'react'
import MainContainer from './MainContainer'
import Typography from '@material-ui/core/Typography'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import Box from '@material-ui/core/Box'
import { Button } from '@material-ui/core'

const NotAuthorized = () => {
  return (
    <MainContainer>
      <Box
        textAlign='center'
      >
        <AccountCircleIcon style={{ fontSize: 120 }} color='primary' />
        <Typography gutterBottom variant='h4' component='h1'>Unfortunately, your library account is missing an email address.</Typography>
        <Typography gutterBottom variant='h5' component='h2'>Please update your profile before submitting a request.</Typography>
        <Box mt={4}>
          <Button
            color='primary'
            size='large'
            variant='contained'
            href='https://reg.calgarylibrary.ca/contact-management'
          >Update your profile
          </Button>
        </Box>
      </Box>

    </MainContainer>
  )
}

export default NotAuthorized
