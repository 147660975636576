const makeRecommendation = async (data, url) => {
  const myHeaders = new window.Headers()
  myHeaders.append('Content-Type', 'application/json')

  const raw = JSON.stringify(data)

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  }

  const fetchData = window.fetch(url, requestOptions)
    .catch(error => console.log('error', error))
  return fetchData
}

export default makeRecommendation
