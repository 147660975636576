import React from 'react'
import TextField from '@material-ui/core/TextField'
import { Controller, useFormContext } from 'react-hook-form'

const BarcodeInput = ({ onChange: handleChange, name, ...props }) => {
  const { control, ...rest } = useFormContext()
  return (
    <Controller
      defaultValue=''
      name={name}
      control={control}
      render={({ field, field: { onChange: rhfOnChange } }) => (
        <TextField
          {...field}
          {...props}
          variant='outlined'
          margin='normal'
          fullWidth
          onChange={(ev) => {
            const { target: { value } } = ev
            rhfOnChange(value)
            handleChange(value)
          }}
        />
      )}
      {...rest}
    />
  )
}

export default BarcodeInput
