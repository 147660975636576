import { createTheme } from '@material-ui/core'

const theme = createTheme({
  palette: {
    primary: {
      main: '#007793'
    },
    secondary: {
      main: '#d93930'
    }
  }
})

export default theme
