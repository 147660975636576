import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import { Button } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Skeleton from '@material-ui/lab/Skeleton'
import { useHistory } from 'react-router'
import { useData } from './DataContext'
import MainContainer from './MainContainer'
import getRecomendationCount from '../api/getRecomendationCount'

const Success = () => {
  const { setValues, data } = useData()
  const { RecommendationCount, EmailAddress, Barcode, isLoading } = data
  const history = useHistory()
  const back = () => {
    history.push('/request-form')
    setValues({ showSpinner: false, isLoading: true })
  }

  useEffect(() => {
    const fetchData = async (email, cn) => {
      const getData = await getRecomendationCount(email, cn)
      const resBody = await getData.text()
      setValues(JSON.parse(resBody))
      setValues({ isLoading: false })
    }
    fetchData(EmailAddress, Barcode)// eslint-disable-next-line
  }, [])
  return (
    <MainContainer>
      <Box
        textAlign='center'
      >
        <Typography gutterBottom variant='h4' component='h1'>
          <span role='img' aria-label='emoji'>🙌</span>
        </Typography>
        <Typography gutterBottom variant='h4' component='h1'>Thank you for your submission!</Typography>

        {isLoading ? <Skeleton animation='wave' /> : <Typography gutterBottom variant='h5' component='h2'>You can submit {5 - RecommendationCount} more request this month.</Typography>}
        <Box mt={4}>
          <Button
            color='primary'
            size='large'
            variant='contained'
            onClick={() => back()}
          >Make another request
          </Button>
        </Box>
      </Box>
    </MainContainer>
  )
}

export default Success
