
import { withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

const CPLButton = withStyles({
  root: {
    borderRadius: 0
  }
})(Button)

export default CPLButton
