import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbar: {
    minHeight: 100,
    justifyContent: 'center'
  },
  cplAppBar: {
    background: '#f4f4f4',
    boxShadow: 'none'
  }

}))
const Header = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <AppBar position='fixed' className={classes.cplAppBar}>
        <Toolbar className={classes.toolbar}>
          <a href='https://calgarylibrary.ca'>
            <img src='/img/logo.svg' alt='Calgary Library logo' />
          </a>
        </Toolbar>
      </AppBar>
      <Toolbar className={classes.toolbar} />
    </div>
  )
}

export default Header
