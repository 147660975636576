import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import MainContainer from './MainContainer'

export default function FormSkeleton () {
  return (
    <MainContainer>
      <Skeleton animation='wave' variant='text' width={200} height={35} />
      <Skeleton animation='wave' variant='text' width={300} />
      <Skeleton animation='wave' variant='text' height={60} />
      <Skeleton animation='wave' variant='text' height={60} />
      <Skeleton animation='wave' variant='text' height={60} />
      <Skeleton animation='wave' variant='text' height={60} />
      <Skeleton animation='wave' variant='text' height={60} />
      <Skeleton animation='wave' style={{ margin: '15px 0 10px 0', borderRadius: '4px' }} variant='rect' height={240} />
      <Skeleton animation='wave' variant='text' height={60} width={150} />
    </MainContainer>
  )
}
