import React from 'react'
import MainContainer from './MainContainer'
// import { Button } from '@material-ui/core'

const Fail = () => {
  return (
    <MainContainer>
      <h1>Success</h1>
    </MainContainer>
  )
}

export default Fail
