const getRecomendationCount = async (email, cn) => {
  const myHeaders = new window.Headers()
  myHeaders.append('Content-Type', 'application/json')

  const raw = JSON.stringify({
    EmailAddress: email,
    Barcode: cn
  })

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  }

  const getData = await window.fetch('https://prod-01.canadacentral.logic.azure.com:443/workflows/3fe143508f654d87940a797a2376eb47/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=H5AZ1k2JfxILveaDpIMqB6W5RDYqV4ioDAnvfJ7D0mc', requestOptions)
    .catch(error => {
      console.log(error)
    })
  return getData
}

export default getRecomendationCount
